function bannerOverlayInlineStyleAlignment(_ref) {
  var _ref$layout = _ref.layout,
      type = _ref$layout.type,
      dialogPosition = _ref$layout.dialogPosition,
      antiAdBlocker = _ref.customCss.antiAdBlocker;
  var style = {
    display: "flex",
    width: "100%",
    height: "100%"
  };

  if (type === "dialog") {
    style.alignItems = dialogPosition.startsWith("top") ? "flex-start" : dialogPosition.startsWith("bottom") ? "flex-end" : "center";
    style.justifyContent = dialogPosition.endsWith("Center") ? "center" : dialogPosition.endsWith("Left") ? "flex-start" : "flex-end";
  }

  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-align",
    style: style
  };
}

export { bannerOverlayInlineStyleAlignment };