function bannerButtonsInlineStyleEllipsesText() {
  return {
    style: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
      overflow: "hidden",
      cursor: "pointer"
    }
  };
}

export { bannerButtonsInlineStyleEllipsesText };