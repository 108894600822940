import { pregQuote } from ".";
/**
 * Port of Clear.php#createRegxpPatternFromWildcardedName.
 */

function createRegxpPatternFromWildcardedName(name) {
  var tmp = name.replace(/\*/g, "PLEACE_REPLACE_ME_AGAIN");
  return "^".concat(pregQuote(tmp).replace(/PLEACE_REPLACE_ME_AGAIN/g, "(.*)"), "$");
}

export { createRegxpPatternFromWildcardedName };