import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from "react";

function getAnimationRelatedState(_ref) {
  var isVisible = _ref.isVisible,
      animationIn = _ref.animationIn,
      animationOut = _ref.animationOut,
      animationInDuration = _ref.animationInDuration,
      animationOutDuration = _ref.animationOutDuration,
      animationInDelay = _ref.animationInDelay,
      animationOutDelay = _ref.animationOutDelay;
  return isVisible ? {
    animation: animationIn,
    duration: animationInDuration,
    delay: animationInDelay
  } : {
    animation: animationOut,
    duration: animationOutDuration,
    delay: animationOutDelay
  };
}
/**
 * Ported from https://www.npmjs.com/package/react-animated-css due to high bundle size.
 */


var AnimatedCss = function AnimatedCss(_ref2) {
  var _ref2$animateOnMount = _ref2.animateOnMount,
      animateOnMount = _ref2$animateOnMount === void 0 ? true : _ref2$animateOnMount,
      _ref2$isVisible = _ref2.isVisible,
      isVisible = _ref2$isVisible === void 0 ? true : _ref2$isVisible,
      _ref2$animationIn = _ref2.animationIn,
      animationIn = _ref2$animationIn === void 0 ? "fadeIn" : _ref2$animationIn,
      _ref2$animationOut = _ref2.animationOut,
      animationOut = _ref2$animationOut === void 0 ? "fadeOut" : _ref2$animationOut,
      _ref2$animationInDela = _ref2.animationInDelay,
      animationInDelay = _ref2$animationInDela === void 0 ? 0 : _ref2$animationInDela,
      _ref2$animationOutDel = _ref2.animationOutDelay,
      animationOutDelay = _ref2$animationOutDel === void 0 ? 0 : _ref2$animationOutDel,
      _ref2$animationInDura = _ref2.animationInDuration,
      animationInDuration = _ref2$animationInDura === void 0 ? 1000 : _ref2$animationInDura,
      _ref2$animationOutDur = _ref2.animationOutDuration,
      animationOutDuration = _ref2$animationOutDur === void 0 ? 1000 : _ref2$animationOutDur,
      _ref2$className = _ref2.className,
      className = _ref2$className === void 0 ? "" : _ref2$className,
      _ref2$style = _ref2.style,
      style = _ref2$style === void 0 ? {} : _ref2$style,
      children = _ref2.children;

  var _useState = useState(animateOnMount ? getAnimationRelatedState({
    isVisible: isVisible,
    animationIn: animationIn,
    animationOut: animationOut,
    animationInDelay: animationInDelay,
    animationOutDelay: animationOutDelay,
    animationInDuration: animationInDuration,
    animationOutDuration: animationOutDuration
  }) : {
    animation: "",
    delay: undefined,
    duration: 0
  }),
      _useState2 = _slicedToArray(_useState, 2),
      _useState2$ = _useState2[0],
      animation = _useState2$.animation,
      duration = _useState2$.duration,
      delay = _useState2$.delay,
      setAnimationRelatedState = _useState2[1];

  useEffect(function () {
    setAnimationRelatedState(getAnimationRelatedState({
      isVisible: isVisible,
      animationIn: animationIn,
      animationOut: animationOut,
      animationInDelay: animationInDelay,
      animationOutDelay: animationOutDelay,
      animationInDuration: animationInDuration,
      animationOutDuration: animationOutDuration
    }));
  }, [isVisible, animationIn, animationOut, animationInDelay, animationOutDelay, animationInDuration, animationOutDuration]);
  var classes = "animate__animated animate__".concat(animation, " ").concat(className);
  var backwardStyle = !animation ? {
    opacity: isVisible ? 1 : 0,
    transition: "opacity ".concat(delay, "ms")
  } : {};
  return h("div", {
    className: classes,
    style: _objectSpread(_objectSpread({
      animationDelay: "".concat(delay, "ms"),
      animationDuration: "".concat(duration, "ms"),
      pointerEvents: isVisible ? "all" : "none"
    }, style), backwardStyle)
  }, children);
};

export { AnimatedCss };