import { calculateOverlay } from "../../../../utils";

function bannerInlineStyleHistorySelect(_ref) {
  var _ref$design = _ref.design,
      fontSize = _ref$design.fontSize,
      fontColor = _ref$design.fontColor,
      _ref$bodyDesign = _ref.bodyDesign,
      descriptionInheritFontSize = _ref$bodyDesign.descriptionInheritFontSize,
      descriptionFontSize = _ref$bodyDesign.descriptionFontSize,
      antiAdBlocker = _ref.customCss.antiAdBlocker;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-history-select",
    style: {
      fontSize: descriptionInheritFontSize ? +fontSize : +descriptionFontSize,
      background: "transparent",
      border: 0,
      borderBottom: "1px solid ".concat(calculateOverlay(fontColor, 50))
    }
  };
}

export { bannerInlineStyleHistorySelect };