function bannerGroupsInlineStyle(_ref) {
  var antiAdBlocker = _ref.customCss.antiAdBlocker;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-groups",
    style: {
      marginTop: 10,
      clear: "both"
    }
  };
}

export { bannerGroupsInlineStyle };