import { bannerButtonsInlineStyleCommon } from "./";

function bannerButtonsInlineStyleSave(_ref, isHover, framed) {
  var isTcf = _ref.isTcf,
      borderRadius = _ref.layout.borderRadius,
      linkTextDecoration = _ref.design.linkTextDecoration,
      _ref$bodyDesign = _ref.bodyDesign,
      acceptAllFontWeight = _ref$bodyDesign.acceptAllFontWeight,
      acceptAllFontSize = _ref$bodyDesign.acceptAllFontSize,
      _ref$saveButton = _ref.saveButton,
      type = _ref$saveButton.type,
      fontSize = _ref$saveButton.fontSize,
      bg = _ref$saveButton.bg,
      textAlign = _ref$saveButton.textAlign,
      borderColor = _ref$saveButton.borderColor,
      padding = _ref$saveButton.padding,
      borderWidth = _ref$saveButton.borderWidth,
      fontColor = _ref$saveButton.fontColor,
      fontWeight = _ref$saveButton.fontWeight,
      hoverBg = _ref$saveButton.hoverBg,
      hoverFontColor = _ref$saveButton.hoverFontColor,
      hoverBorderColor = _ref$saveButton.hoverBorderColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker;
  return bannerButtonsInlineStyleCommon({
    name: "accept-individual-save",
    order: 2,
    fullWidth: true,
    marginLeft: 0,
    marginRight: 0,
    type: type,
    borderRadius: borderRadius,
    bg: bg,
    hoverBg: hoverBg,
    fontSize: isTcf ? acceptAllFontSize : fontSize,
    textAlign: textAlign,
    linkTextDecoration: linkTextDecoration,
    fontColor: fontColor,
    fontWeight: isTcf ? acceptAllFontWeight : fontWeight,
    hoverFontColor: hoverFontColor,
    borderWidth: borderWidth,
    borderColor: borderColor,
    hoverBorderColor: hoverBorderColor,
    padding: padding,
    antiAdBlocker: antiAdBlocker
  }, isHover, framed);
}

export { bannerButtonsInlineStyleSave };