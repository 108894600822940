import { useMemo, useLayoutEffect } from "react";
var counter = 0;
/**
 * Dynamically creates a style-tag in your head with a given plain CSS string.
 */

function usePlainCss(css) {
  var id = useMemo(function () {
    return "react-use-plain-css-".concat((counter++).toString(36));
  }, []);
  useLayoutEffect(function () {
    var element = document.getElementById(id);

    if (!element) {
      element = document.createElement("style");
      element.style.type = "text/css";
      element.id = id;
      document.getElementsByTagName("head")[0].appendChild(element);
    }

    element.innerHTML = css;
    return function () {
      var _element$parentNode;

      (_element$parentNode = element.parentNode) === null || _element$parentNode === void 0 ? void 0 : _element$parentNode.removeChild(element);
    };
  }, [css]);
  return id;
}

export { usePlainCss };