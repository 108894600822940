import _objectSpread from "@babel/runtime/helpers/objectSpread2";

function bannerHeaderInlineStyleLogo(_ref, isRetina) {
  var _ref$headerDesign = _ref.headerDesign,
      logoMaxHeight = _ref$headerDesign.logoMaxHeight,
      logoMargin = _ref$headerDesign.logoMargin,
      logoFitDim = _ref$headerDesign.logoFitDim,
      logoRetinaFitDim = _ref$headerDesign.logoRetinaFitDim,
      antiAdBlocker = _ref.customCss.antiAdBlocker;
  var useFitDim = isRetina ? logoRetinaFitDim : logoFitDim;
  var dimStyle = useFitDim ? {
    width: useFitDim[0],
    height: useFitDim[1]
  } : {
    width: "auto",
    height: +logoMaxHeight
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-logo",
    style: _objectSpread(_objectSpread({}, dimStyle), {}, {
      margin: logoMargin.map(function (m) {
        return "".concat(m, "px");
      }).join(" ") //[logoPosition === "left" ? "marginRight" : "marginLeft"]: logoPosition === "above" ? undefined : 15,
      //marginBottom: logoPosition === "above" ? 5 : undefined

    })
  };
}

export { bannerHeaderInlineStyleLogo };