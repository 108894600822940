var TEST_COOKIE_NAME = "rcb-cookie-test";
/**
 * Check if the current environment supports cookies.
 *
 * @see https://git.io/JcXiP
 * @returns
 */

function supportsCookies() {
  try {
    document.cookie = "".concat(TEST_COOKIE_NAME, "=1");
    var result = document.cookie.indexOf("".concat(TEST_COOKIE_NAME, "=")) !== -1;
    document.cookie = "".concat(TEST_COOKIE_NAME, "=1; expires=Thu, 01-Jan-1970 00:00:01 GMT");
    return result;
  } catch (e) {
    return false;
  }
}

export { supportsCookies };