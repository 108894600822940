/**
 * Use this if you do not have the Mobx store available in your JavaScript asset.
 * E. g. the `banner.js` does not rely on the mobx store and should access the
 * localized options via `window.realCookieBanner` directly.
 */
function getOptionsFromWindow() {
  return window[process.env.slug.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  })];
}

export { getOptionsFromWindow };