/**
 * Let a `Promise` be rejected after a given amount of time.
 */
function promiseTimeout(promise, ms) {
  return new Promise(function (resolve, reject) {
    promise.then(resolve, reject);
    var e = new Error("Timed out");
    setTimeout(reject, ms, e);
  });
}

export { promiseTimeout };