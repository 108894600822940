/**
 * Decode HTML entities in a given string.
 *
 * @see https://stackoverflow.com/a/34064434/5506547
 */
function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export { htmlDecode };