import _extends from "@babel/runtime/helpers/extends";
import { bannerGroupsInlineStyleLinkMore, bannerGroupsInlineStyleCookieProperty } from ".";
import { useBanner } from "../../contexts";
import { isUrl } from "../../utils";

var BannerCookieProperty = function BannerCookieProperty(_ref) {
  var label = _ref.label,
      value = _ref.value,
      children = _ref.children;
  var banner = useBanner();
  var isValueUrl = typeof value === "string" && isUrl(value);
  var useValue = isValueUrl ? h("a", _extends({
    href: value,
    style: {
      lineBreak: isValueUrl ? "anywhere" : undefined
    },
    target: "_blank",
    rel: "noopener noreferrer"
  }, bannerGroupsInlineStyleLinkMore(banner, false)), value) : typeof value === "string" ? h("span", {
    dangerouslySetInnerHTML: {
      __html: value
    }
  }) : value;
  return h("div", _extends({
    key: label
  }, bannerGroupsInlineStyleCookieProperty(banner)), label && h("strong", null, label, ":\xA0"), useValue, !!children && h("div", null, children));
};

export { BannerCookieProperty };