var BUTTONS_WIDTH = 300;

function bannerInlineStylesLeftSide(_ref) {
  var type = _ref.layout.type,
      padding = _ref.bodyDesign.padding,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      individualPrivacyOpen = _ref.individualPrivacyOpen,
      activeAction = _ref.activeAction;
  var style = {
    float: type === "banner" && activeAction !== "history" ? "left" : undefined,
    width: type === "banner" && activeAction !== "history" ? "calc(100% - ".concat(BUTTONS_WIDTH, "px)") : undefined,
    paddingRight: type === "banner" ? 20 : undefined,
    paddingTop: +padding[0],
    paddingBottom: type === "banner" || individualPrivacyOpen ? +padding[2] : 0
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-tb-left",
    style: style
  };
}

export { BUTTONS_WIDTH, bannerInlineStylesLeftSide };