function bannerGroupsInlineStyleCookieProperty(_ref) {
  var _ref$group = _ref.group,
      groupBorderWidth = _ref$group.groupBorderWidth,
      groupBorderColor = _ref$group.groupBorderColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-cookie-prop",
    style: {
      borderLeft: groupBorderWidth > 0 ? "1px solid ".concat(groupBorderColor) : undefined,
      paddingLeft: 15
    }
  };
}

export { bannerGroupsInlineStyleCookieProperty };