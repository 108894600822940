// Currently we can not rely on core-js for frontend banner because
// it increases the bundle size a lot. There is an open issue for this:
// https://github.com/zloirock/core-js/issues/388
// import "core-js/stable/string/virtual/starts-with";
// import "core-js/stable/string/virtual/ends-with";
// import "core-js/features/object/assign";
// import "core-js/features/object/values";
// import "core-js/features/promise";
// https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/String/endsWIth
if (!String.prototype.startsWith) {
  // @see
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
} // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith


if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (searchString, position) {
    var subjectString = this.toString();

    if (typeof position !== "number" || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
      position = subjectString.length;
    }

    position -= searchString.length;
    var lastIndex = subjectString.indexOf(searchString, position);
    return lastIndex !== -1 && lastIndex === position;
  };
} // https://developer.mozilla.org/en-US/docs/Web/API/Element/getAttributeNames


if (Element.prototype.getAttributeNames == undefined) {
  Element.prototype.getAttributeNames = function () {
    var attributes = this.attributes;
    var length = attributes.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++) {
      result[i] = attributes[i].name;
    }

    return result;
  };
} // https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill


(function () {
  if (typeof window.CustomEvent === "function") return;

  function CustomEvent(event, params) {
    params = params || {
      bubbles: false,
      cancelable: false,
      detail: null
    };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  window.CustomEvent = CustomEvent;
})(); // https://github.com/KhaledElAnsari/Object.values


Object.values = Object.values ? Object.values : function (obj) {
  var allowedTypes = ["[object String]", "[object Object]", "[object Array]", "[object Function]"];
  var objType = Object.prototype.toString.call(obj);

  if (obj === null || typeof obj === "undefined") {
    throw new TypeError("Cannot convert undefined or null to object");
  } else if (!~allowedTypes.indexOf(objType)) {
    return [];
  } else {
    // if ES6 is supported
    if (Object.keys) {
      return Object.keys(obj).map(function (key) {
        return obj[key];
      });
    }

    var result = [];

    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        result.push(obj[prop]);
      }
    }

    return result;
  }
}; // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Object/assign

if (typeof Object.assign != "function") {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value: function assign() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var target = args[0]; // .length of function is 2

      "use strict";

      if (target == null) {
        // TypeError if undefined or null
        throw new TypeError("Cannot convert undefined or null to object");
      }

      var to = Object(target);

      for (var index = 1; index < args.length; index++) {
        var nextSource = args[index];

        if (nextSource != null) {
          // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }

      return to;
    },
    writable: true,
    configurable: true
  });
} // https://stackoverflow.com/a/20428914/5506547


(function (arr) {
  arr.forEach(function (item) {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty("remove")) {
      return;
    }

    Object.defineProperty(item, "remove", {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        this.parentNode.removeChild(this);
      }
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);