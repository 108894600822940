/**
 * Wait DOM is ready depending on a state.
 *
 * - `interactive`: Defer scripts are executing, but DOM is ready
 * - `complete`: All scripts including deferred ones are executed and DOM is ready
 *
 * @see https://stackoverflow.com/a/65430734/5506547
 * @see https://stackoverflow.com/a/51980980/5506547
 */
var ready = function ready(fn) {
  var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "complete";
  return new Promise(function (resolve) {
    if (document.readyState === state) {
      fn === null || fn === void 0 ? void 0 : fn();
      resolve();
    } else {
      document.addEventListener("readystatechange", function () {
        if (document.readyState === state) {
          fn === null || fn === void 0 ? void 0 : fn();
          resolve();
        }
      });
    }
  });
};

export { ready };