import { useBanner } from "../../contexts";
import { bannerGroupsInlineStyleDescription, bannerGroupsInlineStyleTitle } from "./inlineStyles";

var BannerGroupSectionTitle = function BannerGroupSectionTitle(_ref) {
  var title = _ref.title,
      children = _ref.children;
  var banner = useBanner();
  return h("div", bannerGroupsInlineStyleTitle(banner), title, children && h("div", bannerGroupsInlineStyleDescription(banner), children));
};

export { BannerGroupSectionTitle };