import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { useMemo } from "react"; // eslint-disable-next-line import/no-extraneous-dependencies

import { renderIconDefinitionToSVGElement } from "@ant-design/icons-svg/es/helpers";
/**
 * Inject a plain SVG icon from `@ant-design/icons-svg`. This reduces the bundle size.
 * You can determine yourself to use `@ant-design/icons` or this helper component. For example,
 * this component is preferred for website bundles where website size matters.
 *
 * @see https://app.clickup.com/t/60pz5t
 */

var PlainAntdIconAsSvg = function PlainAntdIconAsSvg(_ref) {
  var icon = _ref.icon,
      rest = _objectWithoutProperties(_ref, ["icon"]);

  var svgString = useMemo(function () {
    return renderIconDefinitionToSVGElement(icon, {
      extraSVGAttrs: {
        style: "width:auto;height:100%;",
        fill: "currentColor"
      }
    });
  }, [icon]);
  return h("div", _extends({}, rest, {
    dangerouslySetInnerHTML: {
      __html: svgString
    }
  }));
};

export { PlainAntdIconAsSvg };