import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";

var BannerTooltip = function BannerTooltip(_ref) {
  var children = _ref.children,
      title = _ref.title,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? 240 : _ref$width;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isHover = _useState2[0],
      setIsHover = _useState2[1];

  return h("div", {
    style: {
      position: "relative",
      display: "inline"
    }
  }, h("span", {
    onMouseEnter: function onMouseEnter() {
      return setIsHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHover(false);
    }
  }, children), !!title && h("span", {
    style: {
      position: "absolute",
      display: isHover ? undefined : "none",
      width: width,
      backgroundColor: "rgba(0, 0, 0, 0.85)",
      color: "#fff",
      textAlign: "center",
      padding: "5px 10px",
      borderRadius: 6,
      bottom: "125%",
      marginRight: -10,
      right: 0,
      fontSize: 11,
      lineHeight: "16px" // left: "50%",
      // marginLeft: width / 2 * -1

    }
  }, title));
};

export { BannerTooltip };