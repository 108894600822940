function bannerGroupsInlineStyleCookie(_ref) {
  var antiAdBlocker = _ref.customCss.antiAdBlocker;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-cookie",
    style: {
      marginTop: 10
    }
  };
}

export { bannerGroupsInlineStyleCookie };