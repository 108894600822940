import { getOtherOptionsFromWindow } from "../utils";
import matches from "matches-selector";
window.rcbPoweredByCacheOuterHTML = "";
/**
 * Get the server-side-rendered powered by link.
 */

function getSsrPoweredByLink() {
  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      pageRequestUuid4 = _getOtherOptionsFromW.pageRequestUuid4;

  var element = document.getElementById(pageRequestUuid4); // SSR-powered by

  var originalPoweredLink = element.nextElementSibling;
  var poweredLink = document.createElement("div");

  if (window.rcbPoweredByCacheOuterHTML) {
    // We have cached the link as we remove it from the DOM
    poweredLink.innerHTML = window.rcbPoweredByCacheOuterHTML;
  } else if (matches(originalPoweredLink, "a") && originalPoweredLink.innerHTML.toLowerCase().indexOf("Real Cookie Banner")) {
    window.rcbPoweredByCacheOuterHTML = originalPoweredLink.outerHTML;
    poweredLink.innerHTML = window.rcbPoweredByCacheOuterHTML;
    originalPoweredLink.parentNode.removeChild(originalPoweredLink);
  }

  return poweredLink.children[0];
}

export { getSsrPoweredByLink };