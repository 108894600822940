function bannerHeaderInlineStyleSeparator(_ref) {
  var type = _ref.layout.type,
      design = _ref.design,
      _ref$headerDesign = _ref.headerDesign,
      borderWidth = _ref$headerDesign.borderWidth,
      borderColor = _ref$headerDesign.borderColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      headerContainer = _ref.renderedHeights.headerContainer;
  var style = {
    height: +borderWidth,
    background: borderColor,
    position: "sticky",
    top: +headerContainer,
    zIndex: 9
  };

  if (type === "dialog" && design.borderWidth > 0) {
    style.borderLeft = "".concat(design.borderWidth, "px solid ").concat(design.borderColor);
    style.borderRight = style.borderLeft;
  }

  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-header-separator",
    style: style
  };
}

export { bannerHeaderInlineStyleSeparator };