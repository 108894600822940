function bannerFooterInlineStyleSeparator(_ref) {
  var type = _ref.layout.type,
      design = _ref.design,
      _ref$footerDesign = _ref.footerDesign,
      borderWidth = _ref$footerDesign.borderWidth,
      borderColor = _ref$footerDesign.borderColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      footerContainer = _ref.renderedHeights.footerContainer;
  var style = {
    height: +borderWidth,
    background: borderColor,
    position: "sticky",
    bottom: +footerContainer
  };

  if (type === "dialog" && design.borderWidth > 0) {
    style.borderLeft = "".concat(design.borderWidth, "px solid ").concat(design.borderColor);
    style.borderRight = style.borderLeft;
  }

  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-footer-separator",
    style: style
  };
}

export { bannerFooterInlineStyleSeparator };