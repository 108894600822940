import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { BUTTONS_WIDTH } from "./";

function bannerInlineStylesRightSide(_ref) {
  var _style;

  var _ref$layout = _ref.layout,
      type = _ref$layout.type,
      dialogMaxWidth = _ref$layout.dialogMaxWidth,
      _ref$design = _ref.design,
      borderWidth = _ref$design.borderWidth,
      bg = _ref$design.bg,
      headerDesign = _ref.headerDesign,
      footerDesign = _ref.footerDesign,
      padding = _ref.bodyDesign.padding,
      inheritDialogMaxWidth = _ref.individualLayout.inheritDialogMaxWidth,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      _ref$renderedHeights = _ref.renderedHeights,
      headerContainer = _ref$renderedHeights.headerContainer,
      footerContainer = _ref$renderedHeights.footerContainer,
      individualPrivacyOpen = _ref.individualPrivacyOpen;
  var width = type === "dialog" ? +dialogMaxWidth - padding[1] - padding[3] - borderWidth * 2 : BUTTONS_WIDTH;
  var style = (_style = {
    background: bg,
    paddingTop: type === "banner" || individualPrivacyOpen ? +padding[0] : 10,
    paddingBottom: !individualPrivacyOpen ? +padding[2] : 10,
    float: type === "banner" || individualPrivacyOpen ? "right" : undefined,
    maxWidth: width,
    width: width,
    margin: type === "dialog" && individualPrivacyOpen && !inheritDialogMaxWidth ? "0 0 10px 10px" : undefined,
    // position: individualPrivacyOpen ? "sticky" : undefined, This is done by `body.tsx#usePlainCss`
    position: individualPrivacyOpen ? undefined : "sticky"
  }, _defineProperty(_style, individualPrivacyOpen ? "top" : "bottom", individualPrivacyOpen ? +headerContainer + headerDesign.borderWidth : +footerContainer + footerDesign.borderWidth), _defineProperty(_style, "zIndex", 1), _defineProperty(_style, "display", "flex"), _defineProperty(_style, "flexWrap", "wrap"), _style);
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-tb-right",
    style: style
  };
}

export { bannerInlineStylesRightSide };