/**
 * Checks if the given banner is not visible due to legal settings.
 */
function isHiddenDueLegal(banner) {
  var _banner$legal = banner.legal,
      imprint = _banner$legal.imprint,
      imprintHide = _banner$legal.imprintHide,
      privacyPolicy = _banner$legal.privacyPolicy,
      privacyPolicyHide = _banner$legal.privacyPolicyHide,
      pageId = banner.pageId;
  return [imprintHide && +imprint, privacyPolicyHide && +privacyPolicy].filter(Boolean).indexOf(pageId) > -1;
}

export { isHiddenDueLegal };