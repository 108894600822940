function bannerContentInlineStyle(_ref) {
  var type = _ref.layout.type,
      antiAdBlocker = _ref.customCss.antiAdBlocker;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-content",
    style: {
      position: "relative",
      maxHeight: window.innerHeight - (type === "banner" ? 0 : 20),
      overflow: "auto"
    }
  };
}

export { bannerContentInlineStyle };