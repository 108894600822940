/**
 * Ensure the given HTML is a child of `body`. If not, it automatically gets moved.
 */
function ensureBodyElement(element) {
  var _document = document,
      body = _document.body;
  var parentElement = element.parentElement;

  if (parentElement !== body) {
    body.appendChild(element);
  }

  return element;
}

export { ensureBodyElement };