function bannerInlineStyleBodyTeachingsSeparator(_ref) {
  var borderRadius = _ref.layout.borderRadius,
      _ref$bodyDesign = _ref.bodyDesign,
      teachingsSeparatorActive = _ref$bodyDesign.teachingsSeparatorActive,
      teachingsSeparatorWidth = _ref$bodyDesign.teachingsSeparatorWidth,
      teachingsSeparatorHeight = _ref$bodyDesign.teachingsSeparatorHeight,
      teachingsSeparatorColor = _ref$bodyDesign.teachingsSeparatorColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-teachings-separator",
    style: {
      marginTop: 7,
      display: "inline-block",
      maxWidth: "100%",
      borderRadius: +borderRadius,
      width: +teachingsSeparatorWidth,
      height: teachingsSeparatorActive ? +teachingsSeparatorHeight : 0,
      background: teachingsSeparatorColor
    }
  };
}

export { bannerInlineStyleBodyTeachingsSeparator };