import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { useRef, useLayoutEffect } from "react";
import { useBanner } from "../contexts";
/**
 * We are using CSS transitions and we can not surely get the
 * rendered height at time of prop change. E. g. the header
 * title get line broken so the height is another as previously.
 */

var RESOLVE_TRANSITION_ISSUE = [200, 400, 600, 800, 1000];
var RESOLVE_TRANSITION_ISSUE_TIMEOUT;
/**
 * Persist a height to the banner context.
 */

function useBannerRefToRenderedHeight(name) {
  var ref = useRef(); // eslint-disable-next-line @typescript-eslint/no-unused-vars

  var _useBanner = useBanner(),
      renderedHeights = _useBanner.renderedHeights,
      rest = _objectWithoutProperties(_useBanner, ["renderedHeights"]);

  var updateRenderedHeight = rest.updateRenderedHeight;

  var updateSize = function updateSize() {
    var _ref$current;

    return updateRenderedHeight(name, ((_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.getBoundingClientRect().height) || 0);
  };

  useLayoutEffect(function () {
    window.addEventListener("resize", updateSize);
    return function () {
      return window.removeEventListener("resize", updateSize);
    };
  }, [name]); // Always persist on updates, too

  useLayoutEffect(function () {
    updateSize();
    clearTimeout(RESOLVE_TRANSITION_ISSUE_TIMEOUT);
    RESOLVE_TRANSITION_ISSUE_TIMEOUT = setTimeout(function () {
      updateSize();

      var _iterator = _createForOfIteratorHelper(RESOLVE_TRANSITION_ISSUE),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var t = _step.value;
          setTimeout(updateSize, t);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }, 100);
  }, Object.values(rest));
  return ref;
}

export { useBannerRefToRenderedHeight };