import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { getOtherOptionsFromWindow } from "../utils";
/**
 * Apply Consent Forwarding, it set's cross domain cookies via CORS requests.
 *
 * @see https://javascript.info/fetch-crossorigin
 */

function applyConsentForwarding(_ref) {
  var endpoints = _ref.endpoints,
      data = _ref.data;

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      isPro = _getOtherOptionsFromW.isPro;

  if (isPro) {
    /* onlypro:start */
    var promises = [];

    var _iterator = _createForOfIteratorHelper(endpoints),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var endpoint = _step.value;
        promises.push(window.fetch(endpoint, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json;charset=utf-8"
          },
          body: JSON.stringify(data)
        }));
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return Promise.all(promises);
    /* onlypro:start */
  } else {
    return Promise.reject();
  }
}

export { applyConsentForwarding };