function bannerInlineStyleBody(_ref) {
  var _ref$layout = _ref.layout,
      type = _ref$layout.type,
      bannerMaxWidth = _ref$layout.bannerMaxWidth,
      individualLayout = _ref.individualLayout,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      individualPrivacyOpen = _ref.individualPrivacyOpen;
  var style = {
    transition: "width 500ms, max-width 500ms",
    maxWidth: type === "banner" ? +(individualPrivacyOpen && !individualLayout.inheritBannerMaxWidth ? individualLayout.bannerMaxWidth : bannerMaxWidth) : undefined,
    margin: "auto"
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-body",
    style: style
  };
}

export { bannerInlineStyleBody };