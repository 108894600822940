function bannerInlineStyleBodyContainer(_ref) {
  var type = _ref.layout.type,
      _ref$design = _ref.design,
      bg = _ref$design.bg,
      borderWidth = _ref$design.borderWidth,
      borderColor = _ref$design.borderColor,
      padding = _ref.bodyDesign.padding,
      antiAdBlocker = _ref.customCss.antiAdBlocker;
  var style = {
    background: bg,
    // Top / Bottom padding is applied in leftSide and rightSide containers
    padding: padding.map(function (p, i) {
      return "".concat(i === 0 || i === 2 ? 0 : p, "px");
    }).join(" "),
    lineHeight: 1.4
  };

  if (type === "dialog" && borderWidth > 0) {
    style.borderLeft = "".concat(borderWidth, "px solid ").concat(borderColor);
    style.borderRight = style.borderLeft;
  }

  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-body-container",
    style: style
  };
}

export { bannerInlineStyleBodyContainer };