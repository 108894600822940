import { bannerContentInlineStyle, bannerFooterInlineStyleSeparator, bannerHeaderInlineStyleSeparator, BannerHeader, BannerBody, BannerFooter } from ".";
import { useBanner } from "../../contexts";

var BannerContent = function BannerContent() {
  var banner = useBanner();
  /*const { ref } = usePerfectScrollbar({
      parentContainer: `#${pageRequestUuid4}`,
      thumbColor: `${fontColor}4d`
  });*/

  return h("div", bannerContentInlineStyle(banner), h(BannerHeader, null), h("div", bannerHeaderInlineStyleSeparator(banner)), h(BannerBody, null), h("div", bannerFooterInlineStyleSeparator(banner)), h(BannerFooter, null));
};

export { BannerContent };