import { useBanner, useBlocker } from "../contexts";
import { getOtherOptionsFromWindow } from "../utils";
/**
 * Get the permalink of a given page id from our page ID permalink map. It automatically
 * falls back to `p={ID}` URL.
 */

function usePageIdToPermalinkMapLink(pageId) {
  var _useBanner = useBanner(),
      mapBanner = _useBanner.pageIdToPermalink;

  var _useBlocker = useBlocker(),
      mapBlocker = _useBlocker.pageIdToPermalink;

  var pageIdToPermalink = mapBanner || mapBlocker;

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      pageByIdUrl = _getOtherOptionsFromW.pageByIdUrl;

  return (pageIdToPermalink === null || pageIdToPermalink === void 0 ? void 0 : pageIdToPermalink[pageId]) || "".concat(pageByIdUrl, "=").concat(pageId);
}

export { usePageIdToPermalinkMapLink };