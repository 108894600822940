function bannerGroupsInlineStyleTitle(_ref) {
  var _ref$group = _ref.group,
      headlineFontSize = _ref$group.headlineFontSize,
      headlineFontColor = _ref$group.headlineFontColor,
      headlineFontWeight = _ref$group.headlineFontWeight,
      antiAdBlocker = _ref.customCss.antiAdBlocker;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-group",
    style: {
      color: headlineFontColor,
      fontSize: +headlineFontSize,
      fontWeight: headlineFontWeight,
      textAlign: "left"
    }
  };
}

export { bannerGroupsInlineStyleTitle };