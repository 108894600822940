import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from "react";

var AsciiSpinner = function AsciiSpinner(_ref) {
  var _ref$ms = _ref.ms,
      ms = _ref$ms === void 0 ? 100 : _ref$ms,
      _ref$chars = _ref.chars,
      chars = _ref$chars === void 0 ? ["&#9719;", "&#9718;", "&#9717;", "&#9716;"] : _ref$chars;

  var _useState = useState(1),
      _useState2 = _slicedToArray(_useState, 2),
      i = _useState2[0],
      setI = _useState2[1];

  useEffect(function () {
    var interval = setInterval(function () {
      setI(i + 1);
    }, ms);
    return function () {
      return clearInterval(interval);
    };
  }, [i]);
  return h("span", {
    dangerouslySetInnerHTML: {
      __html: chars[i % 4]
    }
  });
};

export { AsciiSpinner };