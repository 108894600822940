function bannerFooterInlineStyleLink(_ref) {
  var _ref$footerDesign = _ref.footerDesign,
      fontSize = _ref$footerDesign.fontSize,
      fontColor = _ref$footerDesign.fontColor,
      hoverFontColor = _ref$footerDesign.hoverFontColor,
      fontInheritFamily = _ref$footerDesign.fontInheritFamily,
      fontFamily = _ref$footerDesign.fontFamily,
      linkTextDecoration = _ref.design.linkTextDecoration,
      antiAdBlocker = _ref.customCss.antiAdBlocker;
  var isHover = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var style = {
    textDecoration: linkTextDecoration,
    fontSize: +fontSize,
    color: isHover ? hoverFontColor : fontColor,
    fontFamily: fontInheritFamily ? undefined : fontFamily,
    padding: "0 5px"
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-footer-link",
    style: style
  };
}

export { bannerFooterInlineStyleLink };