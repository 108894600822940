var overwritten = false;
/**
 * We do not modify `window.onload` directly, you need to use this variable name.
 */

var HTML_ATTRIBUTE_CONSENT_ONLOAD = "rcbInitiatorOnload";
/**
 * Overwrite `window.rcbInitiatorOnload =` assignemnts so they get immediatly executed.
 * At the time of consent the window is definitely loaded.
 */

function applyWindowOnload(doc) {
  // Only overwrite once
  if (overwritten) {
    return;
  }

  var w = doc.defaultView || doc.parentWindow;

  try {
    Object.defineProperty(w, HTML_ATTRIBUTE_CONSENT_ONLOAD, {
      set: function set(newValue) {
        if (typeof newValue === "function") {
          newValue();
        }
      },
      enumerable: true,
      configurable: true
    });
  } catch (e) {// Silence is golden
  }

  overwritten = true;
}

export { HTML_ATTRIBUTE_CONSENT_ONLOAD, applyWindowOnload };