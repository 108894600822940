function getCookieTypeLocalized() {
  return {
    http: {
      name: "HTTP Cookie",
      abbr: "HTTP",
      backgroundColor: "black"
    },
    local: {
      name: "Local Storage",
      abbr: "Local",
      backgroundColor: "#b3983c"
    },
    session: {
      name: "Session Storage",
      abbr: "Session",
      backgroundColor: "#3c99b3"
    },
    flash: {
      name: "Flash Local Shared Object",
      abbr: "Flash",
      backgroundColor: "#b33c3c"
    },
    indexedDb: {
      name: "IndexedDB",
      abbr: "I-DB",
      backgroundColor: "#4ab33c"
    }
  };
}

export { getCookieTypeLocalized };