function bannerFooterInlineStyleContainer(_ref) {
  var _ref$layout = _ref.layout,
      type = _ref$layout.type,
      dialogBorderRadius = _ref$layout.dialogBorderRadius,
      design = _ref.design,
      _ref$footerDesign = _ref.footerDesign,
      inheritBg = _ref$footerDesign.inheritBg,
      bg = _ref$footerDesign.bg,
      inheritTextAlign = _ref$footerDesign.inheritTextAlign,
      textAlign = _ref$footerDesign.textAlign,
      padding = _ref$footerDesign.padding,
      fontSize = _ref$footerDesign.fontSize,
      fontColor = _ref$footerDesign.fontColor,
      fontWeight = _ref$footerDesign.fontWeight,
      antiAdBlocker = _ref.customCss.antiAdBlocker;
  var style = {
    padding: padding.map(function (p) {
      return "".concat(p, "px");
    }).join(" "),
    background: inheritBg ? design.bg : bg,
    borderRadius: type === "dialog" ? "0 0 ".concat(dialogBorderRadius, "px ").concat(dialogBorderRadius, "px") : undefined,
    fontSize: +fontSize,
    fontWeight: fontWeight,
    color: fontColor,
    textAlign: inheritTextAlign ? design.textAlign : textAlign,
    position: "sticky",
    bottom: 0,
    zIndex: 1
  };

  if (type === "dialog" && design.borderWidth > 0) {
    style.borderBottom = "".concat(design.borderWidth, "px solid ").concat(design.borderColor);
    style.borderLeft = style.borderBottom;
    style.borderRight = style.borderBottom;
  }

  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-footer-container",
    style: style
  };
}

export { bannerFooterInlineStyleContainer };