function bannerInlineStyleBodyDottedGroupItem(_ref) {
  var fontSize = _ref.design.fontSize,
      _ref$bodyDesign = _ref.bodyDesign,
      dottedGroupsInheritFontSize = _ref$bodyDesign.dottedGroupsInheritFontSize,
      dottedGroupsFontSize = _ref$bodyDesign.dottedGroupsFontSize;
  return {
    className: undefined,
    style: {
      paddingRight: 10,
      fontSize: dottedGroupsInheritFontSize ? +fontSize : +dottedGroupsFontSize,
      whiteSpace: "nowrap"
    }
  };
}

export { bannerInlineStyleBodyDottedGroupItem };