import { useEffect } from "react";
/**
 * Do not allow scrolling when this banner is visible
 *
 * @param visible
 */

function useRestrictScrollWhenVisible(visible) {
  return useEffect(function () {
    var _document$body = document.body,
        dataset = _document$body.dataset,
        style = _document$body.style; // Save first state

    if (dataset.rcbPreviousOverflow === undefined) {
      dataset.rcbPreviousOverflow = style.overflow;
    }

    style.overflow = visible ? "hidden" : dataset.rcbPreviousOverflow;
    document.body.parentElement.style.overflow = style.overflow;
  }, [visible]);
}

export { useRestrictScrollWhenVisible };