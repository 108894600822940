import _extends from "@babel/runtime/helpers/extends";
import { bannerHeaderInlineStyleContainer, bannerHeaderInlineStyle, bannerHeaderInlineStyleLogo, bannerHeaderInlineStyleHeadline } from ".";
import { useBanner } from "../../contexts";
import { useBannerRefToRenderedHeight } from "../../hooks";
import { getOtherOptionsFromWindow } from "../../utils";

var BannerHeader = function BannerHeader() {
  var banner = useBanner();
  var _banner$headerDesign = banner.headerDesign,
      logo = _banner$headerDesign.logo,
      logoRetina = _banner$headerDesign.logoRetina,
      headline = banner.texts.headline,
      activeAction = banner.activeAction,
      individualPrivacyOpen = banner.individualPrivacyOpen,
      individualTexts = banner.individualTexts;
  var ref = useBannerRefToRenderedHeight("headerContainer");

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      headerTitlePrivacyPolicyHistory = _getOtherOptionsFromW.bannerI18n.headerTitlePrivacyPolicyHistory; // Calculate logo


  var useRetina = logoRetina && !(logo !== null && logo !== void 0 && logo.endsWith(".svg")) && window.devicePixelRatio > 1;
  var useLogo = useRetina ? logoRetina : logo; // Calculate headline

  var useHeadline = individualPrivacyOpen ? activeAction === "history" ? headerTitlePrivacyPolicyHistory : individualTexts.headline : headline;
  return h("div", _extends({
    ref: ref
  }, bannerHeaderInlineStyleContainer(banner)), h("div", bannerHeaderInlineStyle(banner), !!useLogo && h("img", _extends({
    src: useLogo
  }, bannerHeaderInlineStyleLogo(banner, useRetina))), !!useHeadline && h("div", bannerHeaderInlineStyleHeadline(banner), useHeadline)));
};

export { BannerHeader };