/* istanbul ignore file: we do not need to care about the entry point file as errors are detected through integration tests (E2E) */

/**
 * Entrypoint for plain website. This file is only loaded if the visitor needs to accept cookies.
 */
import { render } from "react-dom";
import { WebsiteBanner } from "./components";
import { ready, getOtherOptionsFromWindow, ensureBodyElement } from "./utils";
import { listenPresetOptIn, getSsrPoweredByLink, printUuidToShortcode } from "./others";
import { tcfCmpApi } from "./tcf";
import * as API from "./api";
import { getWebpackPublicPath } from "@devowl-wp/utils";
__webpack_public_path__ = getWebpackPublicPath(process.env);
listenPresetOptIn();
printUuidToShortcode();
tcfCmpApi();
ready(function () {
  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      pageRequestUuid4 = _getOtherOptionsFromW.pageRequestUuid4;

  var poweredLink = getSsrPoweredByLink();
  var element = ensureBodyElement(document.getElementById(pageRequestUuid4));

  if (element) {
    render(h(WebsiteBanner, {
      poweredLink: poweredLink
    }), element);
  }
}, "interactive"); // Expose public API

if (window) {
  window.consentApi = API;
}

export * from "./api";