function bannerInlineStyleBodyDottedGroupBullet(_ref) {
  var dottedGroupsBulletColor = _ref.bodyDesign.dottedGroupsBulletColor;
  return {
    className: undefined,
    style: {
      color: dottedGroupsBulletColor
    }
  };
}

export { bannerInlineStyleBodyDottedGroupBullet };