import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { getOtherOptionsFromWindow, createRegxpPatternFromWildcardedName } from "../utils";
import { OPT_IN_CONTENT_BLOCKER } from "../types";
import { HTML_ATTRIBUTE_COOKIE_IDS, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_BY } from "../others";
/**
 * `window.consentApi.unblock`: Check if a given URL / string is blocked by a Content Blocker:
 *
 * - When a Content Blocker exists, the Promise is only resolved after given consent
 * - When no Content Blocker exists, the Promise is immediate resolved
 *
 * Example (ES5):
 * ```js
 * (window.consentApi && window.consentApi.unblock("player.vimeo.com") || Promise.resolve()).then(function() {
 *     console.log("Consent for Vimeo given, unlock content...!");
 * });
 * ```
 *
 * Example (ES6, Babel, TS):
 * ```js
 * (window.consentApi?.unblock("player.vimeo.com") || Promise.resolve()).then(() => {
 *     console.log("Consent for Vimeo given, unlock content...!");
 * });
 * ```
 *
 * @param url
 * @param ref Create a visual content blocker to a given HTML element
 */

function unblock(url) {
  var ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document.createElement("div");
  return new Promise(function (resolve) {
    // Find matching blocker
    var found;

    var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
        blocker = _getOtherOptionsFromW.blocker;

    var isPseudoRef = !ref.parentElement;

    var _iterator = _createForOfIteratorHelper(blocker),
        _step;

    try {
      loop1: for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var row = _step.value;
        var hosts = row.hosts; // Check if host matches

        var _iterator2 = _createForOfIteratorHelper(hosts),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var host = _step2.value;
            var regexp = createRegxpPatternFromWildcardedName(host);

            if (url.match(regexp)) {
              found = row;
              break loop1;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    if (found) {
      // Add attributes to DOM element so it can be consumed by `manipulateDom`
      ref.setAttribute(HTML_ATTRIBUTE_BY, "cookies");
      ref.setAttribute(HTML_ATTRIBUTE_COOKIE_IDS, found.cookies.join(","));
      ref.setAttribute(HTML_ATTRIBUTE_BLOCKER_ID, found.id.toString());
      ref.addEventListener(OPT_IN_CONTENT_BLOCKER, function () {
        isPseudoRef && ref.remove();
        resolve();
      }); // Append blocking element to HTML DOM if not already exists

      if (isPseudoRef) {
        document.body.appendChild(ref);
      }
    } else {
      resolve();
    }
  });
}

export { unblock };