function bannerGroupsInlineStyleDescription(_ref) {
  var fontWeight = _ref.design.fontWeight,
      _ref$group = _ref.group,
      descriptionFontColor = _ref$group.descriptionFontColor,
      descriptionFontSize = _ref$group.descriptionFontSize,
      antiAdBlocker = _ref.customCss.antiAdBlocker;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-group-description",
    style: {
      color: descriptionFontColor,
      fontSize: +descriptionFontSize,
      fontWeight: fontWeight,
      marginTop: 5
    }
  };
}

export { bannerGroupsInlineStyleDescription };