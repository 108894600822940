import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { Fragment } from "react";
/**
 * Join together a set of JSX elements with a separator.
 *
 * @see https://stackoverflow.com/q/33577448/5506547
 */

function jsxJoin(components, separator) {
  // Just to be sure, remove falsy values so we can add conditionals to the components array
  var filtered = components.filter(Boolean);
  return filtered.length === 0 ? null : filtered.reduce(function (acc, curr, i) {
    return acc.length ? [].concat(_toConsumableArray(acc), [// Wrap the separator in a fragment to avoid `missing key` issues
    h(Fragment, {
      key: i
    }, separator), curr]) : [curr];
  }, []);
}

export { jsxJoin };