import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";

function useResetableAnimation(animation, duration) {
  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      i = _useState2[0],
      setI = _useState2[1];

  var _useState3 = useState(0),
      _useState4 = _slicedToArray(_useState3, 2),
      j = _useState4[0],
      setJ = _useState4[1];

  var _useState5 = useState(animation),
      _useState6 = _slicedToArray(_useState5, 2),
      stateAnimation = _useState6[0],
      setStateAnimation = _useState6[1];

  var _useState7 = useState(undefined),
      _useState8 = _slicedToArray(_useState7, 2),
      stateAnimationReset = _useState8[0],
      setStateAnimationReset = _useState8[1];

  var _useState9 = useState(duration),
      _useState10 = _slicedToArray(_useState9, 2),
      stateDuration = _useState10[0],
      setStateDuration = _useState10[1];

  var _useState11 = useState(undefined),
      _useState12 = _slicedToArray(_useState11, 2),
      stateDurationReset = _useState12[0],
      setStateDurationReset = _useState12[1]; // Reset animation, save it temporary so when changing it, it needs to be to "none" and then the needed one


  useEffect(function () {
    // Avoid first rendering as the first is animated at mount-time
    if (i > 0) {
      if (animation === "none") {
        setStateAnimation(animation);
      } else {
        setStateAnimation("none");
        setStateAnimationReset(animation);
      }
    }

    setI(i + 1);
  }, [animation]);
  useEffect(function () {
    // Avoid first rendering as the first is animated at mount-time
    if (j > 0) {
      if (duration === 0) {
        setStateDuration(duration);
      } else {
        setStateDuration(0);
        setStateDurationReset(duration);
        setStateAnimation("none");
        setStateAnimationReset(animation);
      }
    }

    setJ(j + 1);
  }, [duration]);
  useEffect(function () {
    if (stateAnimationReset !== undefined) {
      setStateAnimation(stateAnimationReset);
      setStateAnimationReset(undefined);
    }
  }, [stateAnimationReset]);
  useEffect(function () {
    if (stateDurationReset !== undefined) {
      setStateDuration(stateDurationReset);
      setStateDurationReset(undefined);
    }
  }, [stateDurationReset]);
  return [stateAnimation, stateDuration];
}

export { useResetableAnimation };