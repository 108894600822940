function getManagerDependingVariables(isManagerActive, setCookiesViaManager) {
  var useVariableNameCamel;
  var useVariableNamePascal;
  var useGlobalObjectName;

  if (isManagerActive) {
    useVariableNameCamel = setCookiesViaManager;
    useVariableNamePascal = useVariableNameCamel.charAt(0).toUpperCase() + useVariableNameCamel.slice(1);
    var isGtm = setCookiesViaManager === "googleTagManager"; // const isMtm = setCookiesViaManager === "matomoTagManager"; // This variable is not yet needed, because we only have 2 managers

    useGlobalObjectName = isGtm ? "dataLayer" : "_mtm";
  }

  return {
    useVariableNameCamel: useVariableNameCamel,
    useVariableNamePascal: useVariableNamePascal,
    useGlobalObjectName: useGlobalObjectName
  };
}

export { getManagerDependingVariables };