import { useMemo } from "react";
import { applyModelMetadata, applyRestrictivePurposes, prepareTcfString, restoreTcf } from "../tcf";

/**
 * Merges the localized `tcf` and `tcfMetadata` together so it can be
 * used inside our `BannerContextProps` and returns a valid `GVL` instance.
 */
function useTcf(active, tcf, tcfMeta) {
  return useMemo(function () {
    if (process.env.IS_TCF === "1" && active && Object.values(tcf.vendors).length > 0) {
      var _restoreTcf = restoreTcf({
        tcf: tcf,
        tcfMeta: tcfMeta,
        tcfString: ""
      }),
          gvl = _restoreTcf.gvl,
          model = _restoreTcf.model;

      applyModelMetadata(model, tcfMeta);
      applyRestrictivePurposes(model, tcf.vendorConfigurations);
      prepareTcfString(model, "initial");
      return {
        gvl: gvl,
        model: model,
        original: tcf,
        metadata: tcfMeta
      };
    } else {
      return undefined;
    }
  }, [active, tcf, tcfMeta]);
}

export { useTcf };