import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { getOtherOptionsFromWindow, getCookie, supportsCookies } from "../utils";
import { explode } from "../utils";

/**
 * Get current user decision from the cookie value.
 */
function getUserDecision() {
  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      userConsentCookieName = _getOtherOptionsFromW.userConsentCookieName;

  var cookieValue = getCookie(userConsentCookieName);

  if (!cookieValue) {
    if (!supportsCookies()) {
      // Use our `window` object instead of cookie value
      return window.rcbDisabledCookieComp || false;
    }

    return false;
  } // Validate cookie length


  var split = explode(cookieValue, ":", 3);

  if (split.length !== 3) {
    return false;
  }

  var _split = _slicedToArray(split, 2),
      uuid = _split[0],
      revision = _split[1];

  var consent = JSON.parse(split[2]);
  return {
    uuid: uuid,
    revision: revision,
    consent: consent
  };
}

export { getUserDecision };