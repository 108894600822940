/**
 * Example: When switching the individual privacy mode, the headline get's updated so
 * we need also recalculate the `position: sticky`.
 *
 * @param delay
 */
function dispatchResizeEvent() {
  var delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  setTimeout(function () {
    try {
      window.dispatchEvent(new Event("resize"));
    } catch (e) {// You are running in IE, it's a UI bug, we can skip...
    }
  }, delay);
}

export { dispatchResizeEvent };