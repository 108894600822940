import { getOptionsFromWindow } from "../../utils";
import { commonRequest } from "@devowl-wp/utils";
import { locationRestConsentClearDelete } from "../../wp-api";
/**
 * Delete cookies on server side (currently only `http`).
 */

function deleteCookiesServer(cookies) {
  // Do this non-blocking
  var _getOptionsFromWindow = getOptionsFromWindow(),
      restNamespace = _getOptionsFromWindow.restNamespace,
      restRoot = _getOptionsFromWindow.restRoot,
      restQuery = _getOptionsFromWindow.restQuery;

  return commonRequest({
    location: locationRestConsentClearDelete,
    options: {
      restNamespace: restNamespace,
      restRoot: restRoot,
      restQuery: restQuery
    },
    params: {
      cookies: cookies.join(",")
    }
  });
}

export { deleteCookiesServer };