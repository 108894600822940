import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { calculateOverlay } from "../../../../utils";

function bannerContentInlineStyleInner(_ref) {
  var _ref$layout = _ref.layout,
      type = _ref$layout.type,
      dialogMaxWidth = _ref$layout.dialogMaxWidth,
      dialogPosition = _ref$layout.dialogPosition,
      dialogMargin = _ref$layout.dialogMargin,
      bannerPosition = _ref$layout.bannerPosition,
      dialogBorderRadius = _ref$layout.dialogBorderRadius,
      _ref$design = _ref.design,
      borderWidth = _ref$design.borderWidth,
      borderColor = _ref$design.borderColor,
      textAlign = _ref$design.textAlign,
      fontColor = _ref$design.fontColor,
      fontInheritFamily = _ref$design.fontInheritFamily,
      fontFamily = _ref$design.fontFamily,
      fontWeight = _ref$design.fontWeight,
      boxShadowEnabled = _ref$design.boxShadowEnabled,
      boxShadowOffsetX = _ref$design.boxShadowOffsetX,
      boxShadowOffsetY = _ref$design.boxShadowOffsetY,
      boxShadowBlurRadius = _ref$design.boxShadowBlurRadius,
      boxShadowSpreadRadius = _ref$design.boxShadowSpreadRadius,
      boxShadowColor = _ref$design.boxShadowColor,
      boxShadowColorAlpha = _ref$design.boxShadowColorAlpha,
      individualLayout = _ref.individualLayout,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      individualPrivacyOpen = _ref.individualPrivacyOpen;
  var width = type === "dialog" ? +(individualPrivacyOpen && !individualLayout.inheritDialogMaxWidth ? individualLayout.dialogMaxWidth : dialogMaxWidth) : "100%";

  var style = _defineProperty({
    pointerEvents: "all",
    transition: "width 500ms, max-width 500ms",
    maxWidth: width,
    textAlign: textAlign,
    width: width,
    overflow: "hidden",
    alignSelf: type === "banner" ? bannerPosition === "top" ? "flex-start" : "flex-end" : undefined,
    fontFamily: fontInheritFamily ? undefined : fontFamily,
    fontWeight: fontWeight,
    color: fontColor,
    margin: type === "dialog" ? 10 : undefined,
    borderRadius: type === "dialog" ? +dialogBorderRadius : undefined,
    boxShadow: boxShadowEnabled ? "".concat(boxShadowOffsetX, "px ").concat(boxShadowOffsetY, "px ").concat(boxShadowBlurRadius, "px ").concat(boxShadowSpreadRadius, "px ").concat(calculateOverlay(boxShadowColor, boxShadowColorAlpha)) : undefined
  }, type === "banner" ? bannerPosition === "top" ? "borderBottom" : "borderTop" : "border", type === "banner" ? borderWidth > 0 ? "".concat(borderWidth, "px solid ").concat(borderColor) : undefined : undefined);

  if (type === "dialog" && dialogPosition !== "middleCenter") {
    style.margin = dialogMargin.map(function (i) {
      return "".concat(i, "px");
    }).join(" ");
  }

  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-inner",
    style: style
  };
}

export { bannerContentInlineStyleInner };