/**
 * Get cookie by name. Please keep in mind you need to `decodeURIComponent` the value in some cases!
 *
 * @param cname
 * @see https://stackoverflow.com/questions/10730362/get-cookie-by-name
 * @see https://stackoverflow.com/a/25490531/5506547
 */
function getCookie(cname) {
  var _document$cookie$matc;

  var decode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var value = ((_document$cookie$matc = document.cookie.match("(^|;)\\s*".concat(cname, "\\s*=\\s*([^;]+)"))) === null || _document$cookie$matc === void 0 ? void 0 : _document$cookie$matc.pop()) || "";
  return decode ? decodeURIComponent(value) : value;
}

export { getCookie };